<template>
  <v-btn color="info" class="" @click="goToDashboard">
    {{ $t("actualizar_plan") }}
  </v-btn>
</template>

<script>
import Api from "@/services/Strapi.js";
export default {
  name: "CustomerPortalButton",
  methods: {
    goToDashboard() {
      let self = this;
      return Api.get("/subscriptions/customer-portal", {}).then(function(resp) {
        window.location.href = resp.data.url;
      });
    }
  }
};
</script>
